/* global styles */
body {
  font-family: 'Raleway', sans-serif;
}

.row {
  max-width: 1200px;
  margin: 0 auto;
}

h1 {
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  font-size: 3.372em;
  font-weight: 300;
  line-height: 64.71px;
  letter-spacing: 0.1em;
  margin: 0 8px 20px 0;
}

h1 strong {
  font-weight: 900;
}

h2, .subpage h1 {
  color: #222222;
  text-transform: uppercase;
  text-align: center;
  font-size: 33px;
  font-weight: 900;
  line-height: 42.42px;
  margin-bottom: -4px;
  margin-top: 18px;
  margin-right: 12px;
  letter-spacing: 0.1em;
}

.subtitle {
  color: #595959;
  font-size: 15px;
  line-height: 42.42px;
  letter-spacing: 0.1em;
  text-align: center;
  margin-right: 12px;
  margin-top: -1px;
}

.subtitle::after {
  content: '';
  display: block;
  width: 71px;
  height: 3px;
  margin: 19px auto 72px;
  background: #11a7e9;
  background: linear-gradient(to right, #11a7e9 0%, #ab6ae7 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#11a7e9', endColorstr='#ab6ae7', GradientType=1);
}

.btn {
  border: 1px solid #ffffff;
  border-radius: 30px;
  outline-style: none;
}

p {
  font-size: 13px;
  line-height: 22.175px;
  color: #101010;
  letter-spacing: 0.2pt;
}

p strong {
  font-size: 14px;
  line-height: 32.35px;
  letter-spacing: 0.1pt;
}

/* logo */
svg.logo {
  max-width: 59px;
  max-height: 62px;
}

svg.logo .st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #FFFFFF;
}

.navbar-default .navbar-brand,
.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus,
.logo-footer,
.logo-footer:hover,
.logo-footer:focus {
  color: #FFFFFF;
  text-transform: uppercase;
  font-size: 17.32px;
  line-height: 27.38px;
  letter-spacing: 0.18em;
  text-decoration: none;
}

.navbar-brand span:first-of-type {
  display: inline-block;
  width: 59px;
}

.navbar-brand span:last-of-type,
.logo-footer span {
  display: inline-block;
  vertical-align: top;
  margin-top: 26px;
  margin-left: 9px;
}

/* navbar bootstrap */
.navbar-default {
  background-color: transparent;
  border-color: transparent;
  margin-bottom: 66px;
}

.navbar > .container-fluid .navbar-brand {
  padding-top: 33px;
  margin-left: -10px;
}

.nav {
  padding-top: 54px;
}

.navbar-right {
  margin-right: -29px;
}

.navbar-default .navbar-nav > li > a {
  color: #ffffff;
  text-transform: uppercase;
  padding: 1.5px 20px;
  line-height: 32.35px;
  letter-spacing: 0.1em;
  position: relative;
}

.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
  color: #FFFFFF;
}

.navbar-default .navbar-nav > li > a span::after {
  content: "";
  display: inline-block;
  width: 0;
  height: 1px;
  background: #fff;
  transition: width 0.5s;
  margin-left: 10%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.navbar-default .navbar-nav > li > a:hover span::after,
.navbar-default .navbar-nav > li > a:focus span::after,
.navbar-default .navbar-nav > li > a.active span::after {
  width: 80%;
}

.navbar-default .navbar-nav > li:nth-of-type(3) > a,
.navbar-default .navbar-nav > li:nth-of-type(4) > a {
  padding-left: 25px;
}

.navbar-default .navbar-nav > li:last-of-type > a {
  background: transparent url(../../../../assets/img/basket.png) no-repeat 15% 50%;
  background-size: 15px 15px;
  padding-right: 30px;
  padding-left: 56px;
  font-weight: bold;
  margin-left: 23px;
  margin-right: 3px;
  transition: all 0.5s;
}

.navbar-default .navbar-nav > li:last-of-type > a:hover {
  background: #fff url(../../../../assets/img/basket-hover.png) no-repeat 15% 50%;
  color: #382574;
}

/* section-header */
.section-header {
  background: url(../../../../assets/img/section-header.jpg) no-repeat;
  background-size: cover;
  max-height: 747px;
}

.section-header p {
  text-align: center;
}

.section-header p a.btn {
  font-size: 13px;
  letter-spacing: 0.28em;
  line-height: 46px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 0 43px 0 52px;
  margin-right: 15px;
  transition: all 0.5s;
}

.section-header p a.btn:hover {
  background: #fff;
  color: #382574;
}

.section-header #e-shop {
  position: relative;
  top: 56px;
  left: 17px;
}

.section-header #e-shop img {
  margin: auto;
  max-width: 957px;
}

/* section-benefits */
.section-benefits {
  padding: 234px 0 77px;
}

.section-benefits > .row > div:nth-of-type(2) {
  padding-left: 33px;
  padding-right: 71px;
  border-right: 1px solid #dedede;
}

.section-benefits > .row > div:nth-of-type(3) {
  padding-left: 130px;
  padding-right: 50px;
}

.section-benefits > .row > div:nth-of-type(4) {
  padding-left: 100px;
  padding-right: 50px;
}

.section-benefits .row .col-md-4 {
  min-height: 484px;
  position: relative;
}

.section-benefits p {
  padding-top: 4px;
}

.section-benefits .benefits .benefit {
  padding-bottom: 40px;
  position: relative;
}

.section-benefits .benefits .benefit::before {
  content: '';
  display: inline-block;
  width: 46px;
  height: 43px;
  background-size: 46px 43px;
  background-repeat: no-repeat;
  position: absolute;
  left: -60px;
}

.section-benefits .benefits .benefit.users::before {
  background-image: url(../../../../assets/img/users.png);
  background-image: linear-gradient(transparent, transparent), url(../../../../assets/img/users.svg);
}

.section-benefits .benefits .benefit.piggy::before {
  background-image: url(../../../../assets/img/piggy.png);
  background-image: linear-gradient(transparent, transparent), url(../../../../assets/img/piggy.svg);
  top: -5px;
}

.section-benefits .benefits .benefit.messages::before {
  background-image: url(../../../../assets/img/messages.png);
  background-image: linear-gradient(transparent, transparent), url(../../../../assets/img/messages.svg);
  top: 4px;
}

.section-benefits .benefits .benefit.speedometer::before {
  background-image: url(../../../../assets/img/speedometer.png);
  background-image: linear-gradient(transparent, transparent), url(../../../../assets/img/speedometer.svg);
}

.section-benefits .benefits .benefit.blog::before {
  background-image: url(../../../../assets/img/blog.png);
  background-image: linear-gradient(transparent, transparent), url(../../../../assets/img/blog.svg);
}

.section-benefits .benefits .benefit.share::before {
  background-image: url(../../../../assets/img/share.png);
  background-image: linear-gradient(transparent, transparent), url(../../../../assets/img/share.svg);
  top: -3px;
}

/* sections */
.section-themes,
.section-demo,
.section-testimonials {
  padding: 32px 0 65px;
}

/* section-themes */
.section-themes {
  background: url(../../../../assets/img/themes.png) no-repeat;
  background-size: cover;
}

.section-themes h2 {
  color: #ffffff;
  margin-top: 44px;
}

.section-themes .subtitle {
  color: #c7c7c7;
}

.section-themes .col-md-12 {
  padding-right: 0;
  padding-left: 0;
}

.section-themes .subtitle::after {
  margin-bottom: 40px;
}

.section-themes .more-themes {
  text-align: center;
}

.section-themes .more-themes a {
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 32.35px;
  letter-spacing: 0em;
  padding: 2.5px 30px;
  transition: all 0.5s;
}

.section-themes .more-themes a:hover {
  background: #fff;
  color: #382574;
}

.section-themes .gallery .gallery-item {
  margin: 0 2.5px;
}

/* gallery */
.gallery .gallery-item {
  position: relative;
}

.gallery .gallery-item {
  display: block;
  float: left;
}

.gallery .gallery-item img {
  width: 100%;
}

.gallery .gallery-item .gallery-item-inner {
  opacity: 0;
  visibility: hidden;
}

.gallery .gallery-item:hover .gallery-item-inner {
  opacity: 1;
  transition-delay: 0s;
  visibility: visible;
}

.gallery .gallery-item .gallery-item-inner {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  transition: visibility 0s linear 0.2s, opacity 0.2s linear 0s;
}

.gallery .gallery-item .gallery-item-inner {
  background-color: rgba(0, 188, 223, 0.8);
}

.gallery .gallery-item .gallery-item-inner .u-display-table {
  height: 100%;
}

.u-display-table {
  display: table;
  width: 100%;
}

.gallery .gallery-item:hover .gallery-item-inner .item-zoomer .item-zoomer-icon {
  opacity: 1;
  top: -5px;
}

.category-list .gallery .gallery-item:hover .gallery-item-inner .item-zoomer .item-zoomer-icon {
  top: 0;
}

.gallery .gallery-item .gallery-item-inner .u-display-table .u-display-tableCell {
  display: table-cell;
  vertical-align: middle;
}

.gallery .gallery-item .item-zoomer .item-zoomer-icon {
  top: -35px;
}

.gallery .gallery-item .item-zoomer .item-zoomer-icon {
  background-clip: padding-box;
  border: 1px solid #fff;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  font-size: 2rem;
  height: 50px;
  line-height: 50px;
  position: relative;
  transition: all 250ms cubic-bezier(0.55, 0, 0.1, 1) 0s;
  width: 50px;
}

.gallery .gallery-item .item-zoomer .item-zoomer-title {
  bottom: -35px;
}

.gallery .gallery-item:hover .gallery-item-inner .item-zoomer .item-zoomer-title {
  bottom: 0;
  opacity: 1;
}

.gallery .gallery-item .item-zoomer .item-zoomer-ico, .gallery .gallery-item .item-zoomer .item-zoomer-title {
  opacity: 0;
}

.gallery .gallery-item .item-zoomer .item-zoomer-title {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 32.35px;
  letter-spacing: 0;
  margin-bottom: 0;
  position: relative;
  text-transform: uppercase;
  transition: all 250ms cubic-bezier(0.55, 0, 0.1, 1) 0s;
  padding: 0 20px;
}

.jcarousel-wrapper {
  margin: 0 auto 47px;
  position: relative;
}

/** Carousel **/
.jcarousel {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.jcarousel ul {
  width: 20000em;
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
}

.jcarousel li {
  width: 200px;
  float: left;
  box-sizing: border-box;
}

.jcarousel img {
  display: block;
  max-width: 100%;
  height: auto !important;
}

/** Carousel Controls **/
.jcarousel-control-prev,
.jcarousel-control-next {
  position: absolute;
  top: 50%;
  margin-top: -34.5px;
  width: 17px;
  height: 69px;
  text-align: center;
  color: #fff;
  text-decoration: none;
}

.jcarousel-control-prev {
  left: -38px;
  background: url(../../../../assets/img/left.png) no-repeat;
}

.jcarousel-control-next {
  right: -38px;
  background: url(../../../../assets/img/right.png) no-repeat;
}

/** Carousel Pagination **/
.jcarousel-pagination {
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translate(-50%, 0);
  margin: 0;
}

.jcarousel-pagination a {
  text-decoration: none;
  display: inline-block;
  font-size: 11px;
  height: 10px;
  width: 10px;
  line-height: 10px;
  background: #fff;
  color: #4E443C;
  border-radius: 10px;
  text-indent: -9999px;
  margin-right: 7px;
  box-shadow: 0 0 2px #4E443C;
}

.jcarousel-pagination a.active {
  background: #4E443C;
  color: #fff;
  opacity: 1;
  box-shadow: 0 0 2px #F0EFE7;
}

/* section-demo */
.section-demo {
  background: url(../../../../assets/img/demo.jpg) no-repeat center center;
  background-size: cover;
}

.section-demo h2 {
  margin-top: 50px;
}

.section-demo .subtitle::after {
  margin-bottom: 32px;
}

.section-demo .col-md-6 p {
  font-size: 13px;
  line-height: 20.35px;
  color: #010101;
  letter-spacing: 0.2pt;
  max-width: 400px;
  margin-bottom: 20px;
  padding-left: 10px;
}

.section-demo .col-md-6 p:nth-of-type(2)::after {
  content: '';
  display: block;
  background: #c0c0c0;
  height: 1px;
  margin: 30px 0 37px -25px;
  width: calc(100% + 25px);
}

.section-demo .btn,
.section-blog .article .read a {
  border-color: #2f2f2f;
  line-height: 32.35px;
  letter-spacing: 0em;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: #222222;
  padding: 2.5px 31.5px 1.5px;
  margin-left: -2px;
  transition: all 0.5s;
}

.section-demo .btn:hover,
.section-blog .article .read a:hover {
  background: #222;
  color: #EAE9E7;
}

.section-demo .link.admin .btn {
  padding: 2.5px 38.5px 1.5px 31.5px;
}

.section-demo .col-md-6 .link {
  margin-bottom: 15px;
}

.section-demo .link + p,
.section-demo .link + p strong {
  font-size: 15px;
  line-height: 25.42px;
  letter-spacing: 0em;
}

/* section-testimonials */
.section-testimonials h2 {
  margin-top: 36px;
}

.section-testimonials .subtitle::after {
  margin-bottom: 42px;
}

.section-testimonials .ref {
  padding-left: 12.5px;
  padding-right: 12.5px;
}

.section-testimonials .ref.first {
  padding-left: 30px;
}

.section-testimonials .ref:last-of-type {
  padding-right: 30px;
}

.section-testimonials .ref-body a {
  border: 1px solid #e6e6e6;
  padding: 23px;
  display: inline-block;
  width: 100%;
  transition: all 0.5s;
  position: relative;
}

.section-testimonials .ref-title a {
  font-size: 15px;
  line-height: 42.42px;
  letter-spacing: 0.1em;
  font-weight: 600;
  color: #595959;
  text-transform: uppercase;
}

.section-testimonials .ref-body,
.section-testimonials .ref-title {
  text-align: center;
}

.section-testimonials .ref .ref-body .overlay {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: rgba(0, 188, 223, 0.8);
  transition: opacity 0.5s;
}

.section-testimonials .ref:hover .ref-body .overlay {
  opacity: 1;
}

.section-testimonials .ref .ref-body .overlay::after {
  content: "\f002";
  color: #fff;
  display: inline-block;
  font-family: FontAwesome;
  line-height: 2;
  font-size: 28px;
  padding-top: 12px;
  position: relative;
  top: 0;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-top: -40px;
  transition: top 0.5s;
}

.section-testimonials .ref:hover .ref-body .overlay::after {
  top: 50%;
}

/* section-write-us */
.section-write-us {
  background: url(../../../../assets/img/write-us.jpg) no-repeat;
  background-size: cover;
  padding: 46px 0 65px;
}

.section-write-us .row {
  display: table;
}

.section-write-us [class*="col-"] {
  float: none;
  display: table-cell;
  vertical-align: middle;
}

.section-write-us .left {
  width: 71%;
}

.section-write-us .right {
  width: 29%;
}

.section-write-us h2 {
  text-align: left;
  color: #ffffff;
  font-size: 37.14px;
  font-weight: 300;
  line-height: 53.37px;
  letter-spacing: 0.1em;
}

.section-write-us h2 strong {
  font-weight: 900;
}

.section-write-us p {
  margin-bottom: 0;
}

.section-write-us .btn {
  color: #ffffff;
  text-transform: uppercase;
  font-size: 14.07px;
  line-height: 52px;
  letter-spacing: 0.28em;
  background: #279ee9;
  border-color: #279ee9;
  padding: 0 54px;
  transition: all 0.5s;
}

.section-write-us .btn:hover {
  background: #fff;
  border-color: #fff;
  color: #382574;
}

/* Form write us */
.form-horizontal .form-group {
  margin-left: 0;
  margin-right: 0;
  position: relative;
}

.white-popup-block {
  background: #fff;
  padding: 20px 40px 30px;
  width: 66.66%;
  margin: auto;
  position: relative;
}

.white-popup-block textarea {
  margin-top: 25px;
  min-height: 183px;
}

.white-popup-block .btn {
  color: #279ee9;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 2.5;
  letter-spacing: 0.28em;
  background: #fff;
  border-color: #279ee9;
  padding: 0 20px;
  float: right;
  transition: all 0.5s;
}

.white-popup-block .btn:hover {
  color: #fff;
  background: #279ee9;
}

.form-control.error,
.form-contro.error:focus {
  border-color: #a94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}

.form-control.valid,
.form-contro.valid:focus {
  border-color: #3c763d;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
}

.form-control-feedback {
  top: 25px;
  font-size: 20px;
}

.form-control-feedback.glyphicon-ok-sign {
  color: #67b168;
}

.form-control-feedback.glyphicon-remove-sign {
  color: #a94442;
}

label.error {
  font-weight: 400;
  color: #a94442;
  margin-top: 5px;
}

/* footer */
.footer {
  background: #0d0d0d;
  padding: 30px 0 7px;
}

.footer > .row:first-of-type {
  border-bottom: 1px solid #3d3d3d;
  padding: 13px 0 40px;
}

.footer > .row:last-of-type {
  padding-top: 18px;
}

.footer > .row > div {
  padding-left: 30px;
  padding-right: 30px;
}

.footer h4, .footer h3 {
  font-size: 20px;
  line-height: 33.37px;
  letter-spacing: 0.1em;
  color: #FFFFFF;
  font-weight: 900;
  text-transform: uppercase;
  margin-top: 0;
}

.footer .contact p {
  font-size: 12px;
  line-height: 20.35px;
  letter-spacing: 0.1em;
  color: #FFFFFF;
}

.footer .contact a,
.footer .contact a:hover,
.footer .contact a:focus {
  color: #279ee9;
  white-space: nowrap;
}

.footer .newsletter > * {
  float: right;
}

.footer .newsletter h3 {
  margin-right: 20px;
}

.footer .newsletter .input-group-btn {
  position: absolute;
  top: 0;
  right: 0;
}

.footer .newsletter .btn-default {
  background: transparent;
  border: none;
  position: relative;
  top: 4px;
  z-index: 10;
}

.footer .newsletter .btn-default:active,
.footer .newsletter .btn-default:focus,
.footer .newsletter .btn-default:hover {
  outline-style: none;
}

.footer #e-mail {
  border-radius: 30px;
  border: 1px solid #FFFFFF;
  font-size: 14px;
  line-height: 36px;
  letter-spacing: 0.1em;
  color: #aaaaaa;
  padding: 0 22px;
  background: linear-gradient(to right, rgba(56, 37, 116, 0.4) 50%, transparent 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  min-width: 275px;
  transition: all 1s;
}

.footer .newsletter .input-group-btn:hover + #e-mail {
  background-position: left bottom;
}

.footer .newsletter .input-group-btn:hover + #e-mail::-webkit-input-placeholder {
  color: #fff;
}

.footer .newsletter .input-group-btn:hover + #e-mail:-moz-placeholder {
  color: #fff;
}

.footer .newsletter .input-group-btn:hover + #e-mail::-moz-placeholder {
  color: #fff;
}

.footer .newsletter .input-group-btn:hover + #e-mail:-ms-input-placeholder {
  color: #fff;
}

.footer #e-mail + span {
  position: absolute;
  right: 5px;
  z-index: 9999;
}

.footer .form-control-feedback {
  right: 10px;
}

.footer .copyright {
  font-size: 11px;
  line-height: 32.35px;
  letter-spacing: 0.1em;
  color: #666666;
  font-weight: 600;
  text-transform: uppercase;
}

.footer .copyright a,
.footer .copyright a:hover,
.footer .copyright a:focus {
  color: #279ee9;
}

.footer .conditions {
  text-align: right;
  font-size: 11px;
  line-height: 32.35px;
  letter-spacing: 0.1em;
  font-weight: 600;
}

.footer .conditions a,
.footer .conditions a:hover,
.footer .conditions a:focus {
  color: #FFFFFF;
  text-transform: uppercase;
}

/** SUBPAGES **/
.section-all-themes,
.section-properties,
.section-blog,
.section-order,
#article {
  padding: 56px 0 75px;
}

/* themes */
.section-all-themes .nav {
  padding-top: 0;
}

.section-all-themes .subtitle::after {
  margin-bottom: 32px;
}

.section-all-themes .category-menu,
.section-all-themes .category-list {
  padding-left: 0;
  padding-right: 0;
}

.section-all-themes .category-list {
  padding-top: 34px;
}

.section-all-themes #portfolio-filter,
.section-all-themes #portfolio-list {
  list-style-type: none;
  padding-left: 0;
  float: left;
  width: 100%;
  position: relative;
}

.section-all-themes #portfolio-filter li,
.section-all-themes #portfolio-list li {
  display: inline-block;
}

.section-all-themes #portfolio-filter li a {
  font-size: 14px;
  line-height: 32.35px;
  letter-spacing: 0em;
  color: #279ee9;
  border-radius: 30px;
  text-transform: uppercase;
  padding: 10px 30px;
  text-decoration: none;
  border: 1px solid transparent;
  transition: all 0.5s;
}

.section-all-themes #portfolio-filter li a.current,
.section-all-themes #portfolio-filter li a:hover,
.section-all-themes #portfolio-filter li a:focus {
  border-color: #279ee9;
  background: #279ee9;
  color: #fff;
}

.section-all-themes #portfolio-list li {
  width: 25%;
  margin: -7px 0 0 -4px;
}

.section-all-themes .gallery--col4 .gallery-item {
  width: 100%;
  padding: 2px;
}

.section-all-themes .gallery .gallery-item .gallery-item-inner {
  margin: 2px;
}

/* properties */
.section-properties .list-properties {
  padding: 0;
  list-style-type: none;
}

.section-properties .list-properties li {
  padding: 12px 0;
  border-bottom: 1px solid #e1e1e1;
}

.section-properties .list-properties span {
  font-size: 8px;
  padding-right: 10px;
}

.section-properties .list-properties .glyphicon {
  position: static;
}

.section-prop-img {
  background: url(../../../../assets/img/themes.png) no-repeat;
  background-size: cover;
  height: 500px;
}

/* blog */
.section-blog .subtitle::after,
#article .subtitle::after {
  margin-bottom: 32px;
}

.section-blog .article {
  margin-bottom: 30px;
}

.section-blog .article .thumbnail {
  border: none;
  padding-top: 50%;
  background: url(../../../../assets/img/blog.jpg) no-repeat;
  background-size: cover;
  border-radius: 0;
  margin-bottom: 0;
  position: relative;
  display: inline-block;
  width: 100%;
}

.section-blog .article .thumbnail .layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #11a7e9;
  background: linear-gradient(to right, #11a7e9 0%, #ab6ae7 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#11a7e9', endColorstr='#ab6ae7', GradientType=1);
  opacity: 0;
  transition: opacity 0.5s;
}

.section-blog .article:hover .thumbnail .layer {
  opacity: 0.4;
}

.section-blog .article .contain {
  border: 1px solid #dddddd;
  border-top: none;
  padding: 21px 15px 5px;
  margin-top: -6px;
}

.section-blog .article .date {
  color: #666666;
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.1em;
  margin-bottom: 5px;
}

.section-blog .article h3 {
  font-size: 17px;
  font-weight: 600;
  text-align: justify;
  line-height: 1;
  letter-spacing: 0em;
  margin-top: 0;
  margin-bottom: 15px;
}

.section-blog .article h3 a {
  color: #101010;
}

.section-blog .article .intro {
  text-align: justify;
}

.section-blog .article .read {
  text-align: right;
}

/* order */
.section-order textarea {
  resize: none;
}

.section-order .right {
  border-left: 1px solid #dedede;
}

.section-order .btn {
  color: #279ee9;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 2.5;
  letter-spacing: 0.28em;
  background: #fff;
  border-color: #279ee9;
  padding: 0 20px;
  float: right;
  transition: all 0.5s;
}

.section-order .btn:hover {
  background: #279ee9;
  color: #fff;
}

/* article */
#article .thumbnail {
  display: inline-block;
  padding-top: 30%;
  width: 100%;
  background-size: cover;
  background-position: center;
}

/** Magnific Popup CSS **/
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #CCC;
}

.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}

@media all and (max-width: 900px) {
  .mfp-arrow {
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    transform-origin: 0;
  }
  .mfp-arrow-right {
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

/** Magnifix PopUp CSS END **/
/** RESPONSIVE **/
@media (max-width: 1300px) {
  .jcarousel-control-prev {
    left: 0;
  }
  .jcarousel-control-next {
    right: 0;
  }
  .jcarousel-wrapper {
    padding: 0 37px;
  }
  .section-themes {
    padding: 32px 15px 65px;
  }
  .section-header #e-shop {
    left: 0;
  }
  .section-testimonials img {
    width: 100%;
    max-width: 309px;
  }
}

@media (max-width: 1124px) {
  .navbar-header {
    float: none;
  }
  .navbar > .container-fluid .navbar-brand {
    display: inline-block;
    width: 100%;
    margin: auto;
    text-align: center;
  }
  .navbar-right {
    margin-right: 0;
    float: none !important;
    text-align: center;
  }
  .navbar-nav > li {
    display: inline-block;
    float: none;
  }
  .nav {
    padding-top: 74px;
  }
}

@media (max-width: 992px) {
  .section-header #e-shop img {
    width: 100%;
  }
  .section-benefits .row .col-md-4 {
    min-height: 100%;
  }
  .section-benefits > .row > div:nth-of-type(2) {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 40px;
    text-align: center;
    border-right: none;
  }
  .section-benefits > .row > div:nth-of-type(3),
  .section-benefits > .row > div:nth-of-type(4) {
    padding-left: 100px;
    padding-right: 35px;
  }
  .section-demo {
    background-position: top left;
  }
  .section-demo .col-sm-12 {
    text-align: center;
  }
  .section-demo .col-md-6 p {
    margin: 0 auto 20px;
  }
  .footer > .row > * {
    text-align: center;
  }
  .footer .logo-footer {
    margin-left: -20px;
  }
  .footer .newsletter {
    margin-top: 35px;
  }
  .footer .newsletter > * {
    float: none;
  }
  .footer .newsletter h3 {
    margin-right: 0;
  }
  .footer .conditions {
    text-align: center;
  }
  .section-testimonials .ref.first {
    padding-left: 15px;
  }
  .section-demo .col-md-6 p {
    padding-left: 0;
  }
  .section-demo .col-md-6 p:nth-of-type(2)::after {
    width: 100%;
    margin: 30px 0 37px 0;
  }
  .section-all-themes #portfolio-list li {
    width: 33.33%;
  }
}

@media (max-width: 900px) {
  .navbar-default .navbar-nav > li:last-of-type > a {
    margin-top: 20px;
  }
  h1 {
    margin-right: 0;
  }
  .section-all-themes #portfolio-filter li {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
  .gallery .gallery-item {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .navbar-header {
    margin-bottom: 70px;
  }
  .nav {
    padding-top: 0;
  }
  .navbar-nav > li {
    display: block;
  }
  .navbar-default .navbar-nav > li:last-of-type > a {
    max-width: 200px;
    margin: 0 auto;
  }
  .section-header {
    max-height: initial;
  }
  .section-benefits > .row > * {
    text-align: center;
  }
  .section-benefits .benefits .benefit::before {
    background-image: none;
  }
  .section-benefits > .row > div:nth-of-type(3),
  .section-benefits > .row > div:nth-of-type(4) {
    padding-left: 15px;
    padding-right: 15px;
  }
  .section-benefits p {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
  h2 {
    margin-right: 0;
  }
  .navbar-default .navbar-toggle {
    border: none;
  }
  .navbar-default .navbar-toggle:hover {
    background-color: unset;
    color: #fff;
  }
  .navbar-default .navbar-toggle:focus {
    background-color: transparent;
  }
  .navbar-default .navbar-toggle .icon-bar {
    background-color: #dddddd;
  }
  .navbar-default .navbar-toggle:hover .icon-bar {
    background-color: #fff;
  }
  .gallery .gallery-item .item-zoomer .item-zoomer-icon {
    width: 45px;
    height: 45px;
    line-height: 45px;
  }
  .section-testimonials .ref-body a {
    max-width: 300px;
  }
  .footer .newsletter .input-group {
    display: block;
  }
  .footer .newsletter > * {
    max-width: 300px;
    margin: auto;
  }
  .footer .newsletter h3 {
    margin-right: auto;
  }
  .section-testimonials .ref:last-of-type {
    padding-right: 12.5px;
  }
  .section-benefits {
    padding: 56px 0 77px;
    position: relative;
    z-index: -1;
  }
  .section-write-us .row {
    display: block;
  }
  .section-write-us [class*="col-"] {
    display: block;
    text-align: center;
  }
  .section-write-us .left,
  .section-write-us .right {
    width: 100%;
  }
  .section-write-us h2 {
    text-align: center;
  }
  .section-write-us .right {
    margin-top: 20px;
  }
  .navbar-toggle .icon-bar {
    transition: all 0.5s;
  }
  .navbar-toggle .icon-bar.open:nth-of-type(2) {
    transform: rotate(45deg);
    position: relative;
    top: 6px;
  }
  .navbar-toggle .icon-bar.open:nth-of-type(3) {
    transform: rotate(-45deg);
  }
  .navbar-toggle .icon-bar:last-of-type {
    opacity: 1;
  }
  .navbar-toggle .icon-bar.open:last-of-type {
    opacity: 0;
  }
  .gallery .gallery-item {
    width: 100%;
  }
  .footer .newsletter .btn-default {
    left: -40px;
    display: block;
  }
}

@media (max-width: 540px) {
  h1,
  .section-write-us h2 {
    font-size: 2em;
    line-height: 1.5;
  }
  h2 {
    font-size: 1.7em;
    line-height: 1.5;
  }
  .subtitle {
    font-size: 12px;
    margin-right: 0;
  }
  .navbar-default {
    margin-bottom: 0;
  }
  .navbar-header {
    margin-bottom: 40px;
  }
  .navbar > .container-fluid .navbar-brand {
    width: auto;
    padding-top: 0;
    padding-left: 0;
    margin: 20px 0 0 -15px;
  }
  .navbar-brand span:first-of-type {
    width: 39px;
  }
  .navbar-brand span:last-of-type {
    margin-top: 14px;
  }
  .navbar-toggle {
    margin-right: -15px;
    margin-top: 25px;
  }
  .navbar-default .navbar-collapse {
    margin: -30px -30px 0;
    padding: 10px 0 30px;
  }
  .section-all-themes #portfolio-list li {
    width: 50%;
  }
  .gallery .gallery-item {
    width: 100%;
  }
}

@media (max-width: 390px) {
  .navbar-brand span:last-of-type {
    font-size: 12px;
  }
  .navbar-default .navbar-collapse {
    overflow: hidden;
  }
  .subtitle::after {
    margin: 19px auto 20px;
  }
  h1,
  .section-write-us h2 {
    font-size: 24px;
  }
  .section-header p a.btn {
    font-size: 11px;
    line-height: 2.5;
    padding: 0 20px;
    margin-right: 0;
    width: 100%;
  }
  .navbar-right {
    margin-right: -15px;
  }
  .footer #e-mail {
    min-width: 100%;
  }
}

/*# sourceMappingURL=styles.css.map */
